import React, { useState } from 'react'
import Compress from './components/Compress'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import {
  BrowserRouter,
  Route,
  Routes
} from 'react-router-dom'
import Login from './pages/Login'
import { Toaster } from 'react-hot-toast'
import Profile from './pages/Profile'
import Dashboard from './dashboard/Dashboard'
import ProtectedRoute from './dashboard/ProtectedRoute'
import ProfileDashboard from './dashboard/ProfileDashboard'
import UserList from './dashboard/UserList'




const App = () => {



  return (
    <div>

      <BrowserRouter>
        <Toaster />
        <Navbar />
        <Routes>
          <Route path='/' element={<Compress />} />
          {/* <Route path='/register' element={<Login />} /> */}
          {/* <Route path='/profile' element={<Profile />} /> */}

          {/* dashboard */}
          {/* <Route element={<ProtectedRoute />} > */}
          {/* <Route path='/dashboard' element={<ProfileDashboard />} /> */}
          {/* <Route path='/dashboard/list' element={<UserList />} /> */}
        {/* </Route> */}


        <Route path='*' element={<h2 className='text-center'>404</h2>} />
      </Routes>
      <Footer />
    </BrowserRouter>

    </div >
  )
}

export default App