import React, { useEffect, useState } from 'react'
import logo from '../assets/Black White Minimalist Logo.png'
import profile from '../assets/profile.png'
import logoutIcon from '../assets/logoutIcon.png'
import { Link, useNavigate, useLocation } from 'react-router-dom'

const Navbar = () => {
    // const navigate = useNavigate()
    // const location = useLocation()


    // const logout = () => {
    //     localStorage.clear()
    //     navigate('/register')
    // }

    // useEffect(() => {
    //     const token = JSON.parse(localStorage.getItem("user"))?.token
    //     if (token && location.pathname == '/register') {
    //         navigate('/')
    //     }
    // }, [])
    // const token = JSON.parse(localStorage.getItem("user"))?.token
    // const role = JSON.parse(localStorage.getItem("user"))?.role


    return (
        <div>


            <nav className="bg-white border-gray-200 dark:bg-white p-2">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                        <img src={logo} className="h-8" alt="compress" />
                        <span className="self-center text-2xl font-semibold whitespace-nowrap text-[#40444f]">Compress-image</span>
                    </Link>
                    <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>
                    <div className="hidden w-full md:flex md:w-auto justify-between" id="navbar-default">
                        <ul className="font-medium md:mr-16 flex flex-col items-center p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-white md:dark:bg-white ">
                            <li>
                                <Link to={'/'} className="block py-2 px-3 hover:text-slate-400 transition-all text-2xl rounded text-black md:bg-transparent md:p-0 " >Home</Link>
                            </li>
                            <li>
                                <a href="#about" className="block py-2 px-3  text-2xl hover:text-slate-400 transition-all text-black rounded t md:border-0 md:p-0 dark:text-dark ">About</a>
                            </li>
                            <li>
                                <a href="#services" className="block py-2 px-3  text-2xl hover:text-slate-400 transition-all text-black rounded t md:border-0  md:p-0 dark:text-dark  ">Services</a>
                            </li>
                        </ul>
                        {/* {
                            role === '0' ?  
                                token ?
                                    <div className='flex gap-9 justify-between items-center'>
                                        <Link to={'/profile'} ><img src={profile} className='w-7 cursor-pointer' alt="" /></Link>
                                         <img src={logoutIcon} className='w-7 cursor-pointer' onClick={logout} alt="" />
                                    </div>
    
                                    :
                                    <Link to={'/register'} type="button" class="focus:outline-none text-dark mx-20 bg-[#40444f] hover:bg-[#40444f] rounded-lg text-sm px-7 py-3 shadow-lg mb-2 dark:bg-[#40444f] dark:hover:bg-[#40444f] text-white hover:text-white font-bold">Login</Link>
    
                            
                            :
                            
                                token ?
                                    <div className='flex gap-9 justify-between items-center'>
                                        <Link to={'/dashboard/list'} ><img src={profile} className='w-7 cursor-pointer' alt="" /></Link>
                                         <img src={logoutIcon} className='w-7 cursor-pointer' onClick={logout} alt="" />
                                    </div>
    
                                    :
                                    <Link to={'/register'} type="button" class="focus:outline-none text-dark mx-20 bg-[#40444f] hover:bg-[#40444f] rounded-lg text-sm px-7 py-3 shadow-lg mb-2 dark:bg-[#40444f] dark:hover:bg-[#40444f] text-white hover:text-white font-bold">Login</Link>
    
                            
                        } */}
                        

                       
                    </div>
                </div>
            </nav>


        </div>
    )
}

export default Navbar