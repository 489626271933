import React from 'react'


const Footer = () => {
  return (
    <div className='bg-black py-4 mt-4 text-white'>
  <div className='flex justify-center items-center flex-col'>
    <p className='text-center text-white text-md'>© 2024 Compress-images. All Rights Reserved.</p>
  </div>
</div>

  )
}

export default Footer