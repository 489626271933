import React, { useState, useRef } from 'react';
import { Grid } from 'semantic-ui-react';
import { useDropzone } from 'react-dropzone';
import imageCompression from 'browser-image-compression';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import box from '../assets/box.png';
import compare from '../assets/compare.jpg';
import CompareImage from 'react-compare-image';
import gif from '../assets/gif.png';
import jpg from '../assets/jpg.png';
import webp from '../assets/webp.png';
import png from '../assets/png.png';
import arrow from '../assets/arrow.gif';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import {Link} from 'react-router-dom'
import toast from 'react-hot-toast'

const Compress = () => {
  const [origImgs, setOrigImgs] = useState([]);
  const [origImgFiles, setOrigImgFiles] = useState([]);
  const [compressImgs, setCompressImgs] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [originalSize, setOriginalSize] = useState([]);
  const [convert, setConvert] = useState(false);
  const [format, setFormat] = useState('jpg');
  const [border, setBorder] = useState(false);
  const [border1, setBorder1] = useState(false);
  const [border2, setBorder2] = useState(false);
  const [percentageReductions, setPercentageReductions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false)
  const [open1, setOpen1] = useState(false)
  const fileInputRef = useRef(null);
  const uploadSectionRef = useRef(null);

  //1 Handle file drop
  const onDrop = async (acceptedFiles) => {
    const token = JSON.parse(localStorage.getItem("user"))?.token;

    // Check if more than 10 images are being uploaded
    if (origImgs.length + acceptedFiles.length > 10) {
      setOpen(true)
      return;
    }

    // Check for large files and handle according to the token presence
    const largeFiles = acceptedFiles.filter(file => file.size > 10 * 1024 * 1024); // 10 MB in bytes
    if (largeFiles.length > 0) {
      setOpen1(true)
      return;
    }

    // If token exists, allow the upload
      setOrigImgs(prevImgs => [...prevImgs, ...acceptedFiles]);
      setOrigImgFiles(prevFiles => [...prevFiles, ...acceptedFiles.map(file => URL.createObjectURL(file))]);
      setFileNames(prevNames => [...prevNames, ...acceptedFiles.map(file => file.name)]);
      setSizes(prevSizes => [...prevSizes, ...acceptedFiles.map(file => file.size)]);

      // Call handleCompressImg to compress images immediately
      await handleCompressImg(acceptedFiles);
    // } else {
    //   // Handle the case where no token is present
    //   const smallFiles = acceptedFiles.filter(file => file.size <= 10 * 1024 * 1024); // 10 MB in bytes
    //   if (smallFiles.length === acceptedFiles.length) {
    //     setOrigImgs(prevImgs => [...prevImgs, ...acceptedFiles]);
    //     setOrigImgFiles(prevFiles => [...prevFiles, ...acceptedFiles.map(file => URL.createObjectURL(file))]);
    //     setFileNames(prevNames => [...prevNames, ...acceptedFiles.map(file => file.name)]);
    //     setSizes(prevSizes => [...prevSizes, ...acceptedFiles.map(file => file.size)]);

    //     // Call handleCompressImg to compress images immediately
    //     await handleCompressImg(smallFiles);
    //   } else {
    //     toast.error('All files must be 10MB.');
    //   }
    // }
  };

  //2 images compress function
  const handleCompressImg = async (files) => {
    setIsLoading(true);
    if (fileInputRef.current) {
      fileInputRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    try {
      // const token = JSON.parse(localStorage.getItem("user"))?.token;

      // Check and format the sizes of the images
      const sizes = files.map((img) => {
        const sizeInBytes = img.size;
        return sizeInBytes > 1024 * 1024
          ? `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`
          : `${(sizeInBytes / 1024).toFixed(2)} KB`;
      });

      setOriginalSize(sizes);

      const option = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      const compressedImages = await Promise.all(files.map(async (img) => {
        if (img.size / 1024 / 1024 > 10) {
          toast.error('One or more images exceed the maximum allowed size of 10MB.');
          // setOpen1(true)
          return null;
        }

        try {
          // Compress the image
          const compressedImg = await imageCompression(img, option);

          // Convert to PNG if the selected format is PNG
          if (format === 'png') {
            const imgUrl = URL.createObjectURL(compressedImg);
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const image = new Image();
            image.src = imgUrl;

            await new Promise((resolve, reject) => {
              image.onload = () => {
                canvas.width = image.width;
                canvas.height = image.height;
                ctx.drawImage(image, 0, 0);
                resolve();
              };
              image.onerror = (error) => {
                console.error('Error loading image for PNG conversion:', error);
                reject(error);
              };
            });

            const pngBlob = await new Promise((resolve, reject) => canvas.toBlob((blob) => {
              if (blob) {
                resolve(blob);
              } else {
                reject(new Error('Failed to create PNG blob'));
              }
            }, 'image/png'));

            return new File([pngBlob], img.name.replace(/\.[^/.]+$/, ".png"), { type: 'image/png' });
          }

          return compressedImg;
        } catch (compressionError) {
          console.error('Error compressing image:', compressionError);
          return null;
        }
      }));

      const validCompressedImages = compressedImages.filter(img => img !== null);

      if (validCompressedImages.length === 0) {
        toast.error('No images were successfully compressed.');
        return;
      }

      const compressedImgUrls = validCompressedImages.map(img => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            resolve(event.target.result);
          };
          reader.onerror = (error) => {
            console.error('Error reading compressed image file:', error);
            reject(error);
          };
          reader.readAsDataURL(img);
        });
      });

      const compressedImgUrlsResolved = await Promise.all(compressedImgUrls);
      setCompressImgs(validCompressedImages);

      const newSizes = validCompressedImages.map(img => img.size);
      setSizes(newSizes);

      const percentageReductions = validCompressedImages.map((img, idx) => {
        const originalSize = files[idx].size;
        const compressedSize = img.size;
        const reduction = ((originalSize - compressedSize) / originalSize) * 100;
        return reduction.toFixed(2);
      });

      setPercentageReductions(percentageReductions);

      console.log("Compressed Images:", validCompressedImages);
      if (format === 'png') {
        console.log("Converted to PNG:", validCompressedImages);
      }

      percentageReductions.forEach((reduction, idx) => {
        console.log(`Image ${idx + 1} reduced by ${reduction}%`);
      });

    } catch (error) {
      console.error('An unexpected error occurred:', error);
    } finally {
      setIsLoading(false); // Hide loading overlay
    }
  };

  //3 all images download
  const handleDownloadAll = () => {
    const zip = new JSZip();

    compressImgs.forEach((img, idx) => {
      const fileName = fileNames[idx].split('.')[0] + (format ? `.${format}` : fileNames[idx].substring(fileNames[idx].lastIndexOf('.')));

      const blob = new Blob([img], { type: img.type });
      const reader = new FileReader();
      reader.onload = () => {
        const dataUrl = reader.result.split(',')[1];
        zip.file(fileName, dataUrl, { base64: true });

        if (idx === compressImgs.length - 1) {
          zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, 'compressed_images.zip');
          });
        }
      };
      reader.readAsDataURL(blob);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: true
  });

  


  //4 if selected the multiple images and converted images click single image download
  const handleDownloadSingle = (img, fileName) => {
    const blob = new Blob([img], { type: `image/${format}` });
    const url = URL.createObjectURL(blob);
    const modifiedFileName = fileName.replace(/\.[^/.]+$/, `.${format}`);
    const link = document.createElement('a');
    link.href = url;
    link.download = modifiedFileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  //5 converted images delete one by one
  const handleDelete = (index) => {
    setCompressImgs(compressImgs.filter((_, i) => i !== index));
    setFileNames(fileNames.filter((_, i) => i !== index));
    setOriginalSize(originalSize.filter((_, i) => i !== index));
    setSizes(sizes.filter((_, i) => i !== index));
    setPercentageReductions(percentageReductions.filter((_, i) => i !== index));
  };

  
  //6 scroll select images
  const handleConvertAndScroll = (format) => {
    setFormat(format);

    if (uploadSectionRef.current) {
      uploadSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  const handleConvertImage = (format) => {
    setFormat(format);
  };



  return (
    <div>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-container">
            <div className="loading-bar"></div>
          </div>
        </div>
      )}
      <container className="main min-h-[500px]">
        <Grid className="cursor-default">
          <Grid.Row>
            <Grid.Column width={16}>
              <div className="flex flex-col-reverse md:flex-row justify-around items-center -mt-5">
                <div className="image-comparison-container" ref={uploadSectionRef}>
                  <h2 className="text-2xl md:text-3xl text-[#40444f] pt-3 font-bold">Can you tell the difference?</h2>
                  <CompareImage leftImage={compare} rightImage={compare} className="rounded-xl" sliderLineColor="#000000" />
                  <div className='flex items-center justify-between'>
                    <div>
                      <h2 className="text-left text-black font-bold mt-3 md:mt-5">Compress your images</h2>
                      <h2 className="text-left text-black font-bold -mt-3">online 100% free</h2>
                    </div>
                  </div>
                </div>
                <div className={`image-uploader mt-5 mx-5 md:mx-20 rounded-md w-full md:w-[500px] filter backdrop-blur-md min-h-[${!convert ? "430px" : "460px"}]`}>
                  <div className="pb-10">
                    <div {...getRootProps({ className: 'custom-file-upload text-2xl md:text-4xl cursor-pointer pt-10 md:pt-20 text-black rounded-lg' })}>
                      <input {...getInputProps()} />
                      <div className="flex flex-col items-center justify-center border-2 border-dashed rounded-lg mx-3 md:mx-9 -mt-10 h-[200px] md:h-[300px] border-white w-[90%] md:w-[450px]">
                        <img src={box} className="w-[100px] md:w-[200px]" alt="" />
                        <h2 className="font-bold text-[#40444f] -mt-5">
                          Drop your images here!
                        </h2>
                        <small className="text-[#40444f] text-sm">Up to 20 images max 10mb each</small>
                      </div>
                    </div>

                    <div className="flex bg-slate-100 justify-between items-center mt-2 px-4">
                      <label>
                        <div className="p-2 mb-3">
                          <div className="flex cursor-pointer mx-2 mt-5">
                            <input type="checkbox" className="sr-only peer" onClick={() => setConvert(!convert)} />
                            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                            <span className="ms-3 text-md text-black dark:black">
                              Convert my images automatically
                            </span>
                          </div>
                        </div>
                      </label>
                      <div className="flex justify-end items-center">
                        {/* {origImgFiles.length > 0 && (
                          <button
                            className="focus:outline-none flex items-center mt-2 text-dark bg-white rounded-lg text-sm px-4 py-2 shadow-lg mb-2 dark:bg-white text-black dark:hover:bg-white font-bold"
                            onClick={handleCompressImg}
                          >
                            Compress Images <span><img src={arrow} width={'25px'} alt="" /></span>
                          </button>
                        )} */}
                        <h1></h1>
                      </div>
                    </div>

                    <div className={`${!convert ? "hidden" : ""} bg-[#f4faeb] -mt-3 rounded-b-md`}>
                      <div className="flex gap-4 mb-10 mx-10 mt-3 p-3">
                        <button className={`w-[100px] border-[1px] ${border ? "border-blue-400" : "border-black"} ${border ? "text-blue-300" : "text-black"} hover:border-blue-400 p-[6px] bg-white text-[#40444f] transition-all rounded-full`} onClick={() => handleConvertImage('png')}>PNG</button>
                        <button className={`w-[100px] border-[1px] ${border1 ? "border-blue-400" : "border-black"} ${border1 ? "text-blue-300" : "text-black"} p-[6px] hover:border-blue-400 bg-white text-[#40444f] transition-all rounded-full`} onClick={() => handleConvertImage('webp')}>WEBP</button>
                        <button className={`w-[100px] border-[1px] ${border2 ? "border-blue-400" : "border-black"} ${border2 ? "text-blue-300" : "text-black"} p-[6px] hover:border-blue-400 bg-white text-[#40444f] transition-all rounded-full`} onClick={() => handleConvertImage('jpg')}>JPG</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h1></h1>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </container>




      <div className='max-w-[1100px] mx-auto p-5' id='converted' ref={fileInputRef}>
        <header className='flex bg-[#40444f] justify-between items-center p-5 rounded-md flex-wrap'>
          <div>
            <h1 className='text-[#8cc938]'>George the Panda just saved you 70%</h1>
            <h3 className='text-md text-white'>{compressImgs.length} images optimized</h3>
          </div>
          <div>
            {compressImgs.length > 0 && <button className='bg-[#8cc938] w-[200px] p-4 rounded-md text-white font-bold mt-3 md:mt-0' onClick={handleDownloadAll}>Download All</button>}
          </div>
        </header>

        {compressImgs.map((img, idx) => (
          <div key={idx} className='flex flex-col justify-between items-center mt-2'>
            <div className='flex justify-between items-center w-full'>
              <img src={URL.createObjectURL(img)} className='w-[100px] h-[80px] rounded-md' alt="" />
              <div className='flex-1'>
                <h3 className='text-[#40444f] mx-10 w-[300px]'>{fileNames[idx]}</h3>
                <h6 className='-mt-3 mx-10'>{originalSize[idx]}</h6>
              </div>
              <div className='flex justify-between items-center'>
                <div className='mx-5 w-[200px] mt-3 text-center flex justify-between items-center'>
                  <h3 className=''>{percentageReductions[idx]}%</h3>
                  <h3 className='-mt-1'>{format.toUpperCase()} <br /> <span className='text-[13px]'>{Math.round(sizes[idx] / 1024)} kb</span></h3>
                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleDelete(idx)} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 mx-12 cursor-pointer">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                  </svg>
                  <br />
                  <button
                    onClick={() => handleDownloadSingle(img, fileNames[idx])}
                    className='bg-[#8cc938] p-2 text-sm rounded-md -mt-5 text-white'
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>
            <div className='h-[1px] bg-black mt-2 w-full' />
          </div>
        ))}

        {compressImgs.length > 0 &&
          <div className='bg-[#40444f] flex items-center max-w-[1100px] mx-auto mt-3 justify-center p-3 rounded-md'>
            <button className='bg-[#8cc938] w-[200px] p-4 rounded-md text-white font-bold' onClick={handleDownloadAll}>Download All</button>
          </div>
        }
      </div>
      <div className='bg-slate-200 mt-10 pb-7' id='services'>
        <h2 className='text-center pt-3 text-[#8cc938]'>Convert your images</h2>
        <div className='pt-5 p-6 flex flex-col md:flex-row justify-around items-center gap-5'>
          <div className='bg-white text-center w-full md:w-[45%] lg:w-[45%] flex flex-col justify-center rounded-lg items-center pt-4 h-[270px] shadow-md shadow-gray-500'>
            <img src={jpg} width={'40px'} alt="" />
            <h2>JPG to PNG</h2>
            <p className='p-2'>Convert JPG to PNG effortlessly with our fast and reliable tool. Enjoy high-quality results and easy conversions!</p>
            <button className='bg-[#8cc938] w-[200px] p-4 rounded-md text-white font-bold' onClick={() => handleConvertAndScroll('png')}>JPG to PNG</button>
          </div>
          <div className='bg-white text-center w-full md:w-[45%]  lg:w-[45%] flex flex-col justify-center rounded-lg items-center pt-4 h-[270px] shadow-md shadow-gray-500'>
            <img src={png} width={'40px'} alt="" />
            <h2>PNG to JPG</h2>
            <p className='p-2'>Convert PNG to JPG easily with our efficient tool. Get high-quality results and simple conversions in no time!</p>
            <button className='bg-[#8cc938] w-[200px] p-4 rounded-md text-white font-bold' onClick={() => handleConvertAndScroll('jpg')}>PNG to JPG</button>
          </div>
        </div>
        <div className='pt-5 p-6 flex flex-col md:flex-row justify-around items-center gap-5 mt-5'>
          <div className='bg-white text-center w-full md:w-[45%] lg:w-[45%] flex flex-col justify-center rounded-lg items-center pt-4 h-[270px] shadow-md shadow-gray-500'>
            <img src={webp} width={'40px'} alt="" />
            <h2>GIF to WEBP</h2>
            <p className='p-2'>Convert GIF to WebP effortlessly with our tool. Enjoy optimized, high-quality images with reduced file sizes!</p>
            <button className='bg-[#8cc938] w-[200px] p-4 rounded-md text-white font-bold' onClick={() => handleConvertAndScroll('webp')}>GIF to WEBP</button>
          </div>
          <div className='bg-white text-center w-full md:w-[45%] lg:w-[45%] flex flex-col justify-center rounded-lg items-center pt-4 h-[270px] shadow-md shadow-gray-500'>
            <img src={gif} width={'40px'} alt="" />
            <h2>JPG to GIF</h2>
            <p className='p-2'>Convert JPG to GIF with ease. Quickly transform your images into animated GIFs or static graphics with high quality!</p>
            <button className='bg-[#8cc938] w-[200px] p-4 rounded-md text-white font-bold' onClick={() => handleConvertAndScroll('gif')}> JPG to GIF</button>
          </div>
        </div>
      </div>



      {/* about */}
      <div className='w-full max-w-[1000px] mx-auto bg-white mt-8 p-4 rounded-lg shadow-lg pb-10 shadow-gray-600' id='about'>
        <div className='flex flex-col md:flex-row p-4 justify-between items-center'>
          <div className='mb-4 md:mb-0'>
            <h4 className='text-[#8cc938]'>--Image Compression is Our Expertise</h4>
            <h2 className='-mt-3'>Optimize Your  <br />Images <span className='text-[#8cc938]'>Effortlessly</span></h2>
            <p>Quickly convert and compress JPG, PNG, and GIF images to improve website speed and performance. Enjoy high-quality results   with reduced file sizes</p>
          </div>
          <img src={compare} className='w-full md:w-[300px] rounded-md h-[200px]' alt="" />
        </div>
      </div>


      <Dialog open={open} onClose={setOpen} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    {/* <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Login here!
                    </DialogTitle> */}
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                      maximum allow 10 images
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                 Cancel
                </button>
                {/* <Link
                  type="button"
                  data-autofocus
                  to={'/register'}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Login
                </Link> */}
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>

      <Dialog open={open1} onClose={setOpen1} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    {/* <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Login here!
                    </DialogTitle> */}
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                      One or more images exceed the maximum allowed size of 10MB.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                 Cancel
                </button>
                {/* <Link
                  type="button"
                  data-autofocus
                  to={'/register'}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Login
                </Link> */}
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>




    </div>






  );
};

export default Compress;


